import {ReactNode} from 'react';

export enum GoogleFonts {
  'Roboto' = 'Roboto',
  'Open Sans' = 'Open Sans',
  'Noto Sans JP' = 'Noto Sans JP',
  'Montserrat' = 'Montserrat',
  'Lato' = 'Lato',
  'Poppins' = 'Poppins',
  'Roboto Condensed' = 'Roboto Condensed',
  'Source Sans Pro' = 'Source Sans Pro',
  'Inter' = 'Inter',
  'Roboto Mono' = 'Roboto Mono',
  'Oswald' = 'Oswald',
  'Raleway' = 'Raleway',
  'Noto Sans' = 'Noto Sans',
  'Roboto Slab' = 'Roboto Slab',
  'Ubuntu' = 'Ubuntu',
  'Nunito' = 'Nunito',
  'Nunito Sans' = 'Nunito Sans',
  'Rubik' = 'Rubik',
  'Playfair Display' = 'Playfair Display',
  'Merriweather' = 'Merriweather',
  'PT Sans' = 'PT Sans',
  'Mukta' = 'Mukta',
  'Noto Sans KR' = 'Noto Sans KR',
  'Work Sans' = 'Work Sans',
  'Kanit' = 'Kanit',
  'Lora' = 'Lora',
  'Noto Sans TC' = 'Noto Sans TC',
  'Fira Sans' = 'Fira Sans',
  'Quicksand' = 'Quicksand',
  'Barlow' = 'Barlow',
  'Inconsolata' = 'Inconsolata',
  'Mulish' = 'Mulish',
  'PT Serif' = 'PT Serif',
  'Titillium Web' = 'Titillium Web',
  'IBM Plex Sans' = 'IBM Plex Sans',
  'Heebo' = 'Heebo',
  'Noto Serif' = 'Noto Serif',
  'DM Sans' = 'DM Sans',
  'Nanum Gothic' = 'Nanum Gothic',
  'Libre Franklin' = 'Libre Franklin',
  'Hind Siliguri' = 'Hind Siliguri',
  'Manrope' = 'Manrope',
  'Karla' = 'Karla',
  'Josefin Sans' = 'Josefin Sans',
  'Arimo' = 'Arimo',
  'Libre Baskerville' = 'Libre Baskerville',
  'Dosis' = 'Dosis',
  'PT Sans Narrow' = 'PT Sans Narrow',
  'Bebas Neue' = 'Bebas Neue',
  'Source Serif Pro' = 'Source Serif Pro',
  'Bitter' = 'Bitter',
  'Cairo' = 'Cairo',
  'Source Code Pro' = 'Source Code Pro',
  'Oxygen' = 'Oxygen',
  'Cabin' = 'Cabin',
  'Anton' = 'Anton',
  'Dancing Script' = 'Dancing Script',
  'Lobster' = 'Lobster',
  'Abel' = 'Abel',
  'Prompt' = 'Prompt',
  'Pacifico' = 'Pacifico',
  'Noto Sans SC' = 'Noto Sans SC',
  'Exo 2' = 'Exo 2',
  'Comfortaa' = 'Comfortaa',
  'EB Garamond' = 'EB Garamond',
  'Barlow Condensed' = 'Barlow Condensed',
  'Hind' = 'Hind',
  'Maven Pro' = 'Maven Pro',
  'Signika Negative' = 'Signika Negative',
  'Space Grotesk' = 'Space Grotesk',
  'Noto Serif JP' = 'Noto Serif JP',
  'Crimson Text' = 'Crimson Text',
  'Varela Round' = 'Varela Round',
  'Fjalla One' = 'Fjalla One',
  'Rajdhani' = 'Rajdhani',
  'Jost' = 'Jost',
  'Teko' = 'Teko',
  'Arvo' = 'Arvo',
  'Material Symbols Outlined' = 'Material Symbols Outlined',
  'Archivo' = 'Archivo',
  'Assistant' = 'Assistant',
  'Caveat' = 'Caveat',
  'Merriweather Sans' = 'Merriweather Sans',
  'Asap' = 'Asap',
  'Abril Fatface' = 'Abril Fatface',
  'Hind Madurai' = 'Hind Madurai',
  'Shadows Into Light' = 'Shadows Into Light',
  'Public Sans' = 'Public Sans',
  'Righteous' = 'Righteous',
  'Cormorant Garamond' = 'Cormorant Garamond',
  'Satisfy' = 'Satisfy',
  'Slabo 27px' = 'Slabo 27px',
  'Fira Sans Condensed' = 'Fira Sans Condensed',
  'Overpass' = 'Overpass',
  'IBM Plex Mono' = 'IBM Plex Mono',
  'Tajawal' = 'Tajawal',
  'Sen' = 'Sen',
}
export type Fonts = GoogleFonts | string;

export interface NavItem {
  to?: string;
  altMatch?: string;
  icon?: ReactNode;
  label: ReactNode;
  active?: boolean;
  children?: NavItem[];
  hideShadow?: boolean;
  externalLink?: boolean;
}

export interface Creator {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
}
